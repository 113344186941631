<template>
  <!-- eslint-disable vue/max-attributes-per-line -->
  <!-- eslint-disable vue/html-self-closing -->
  <validation-observer ref="simpleRules">
    <b-form @submit.prevent>
      <b-row>
        <b-col md="9">
          <Fieldset id="information" :title="$t('Basic Information')">
            <b-form-group :label="`*` + $t('Name') + `:`" label-cols-md="12">
              <validation-provider
                #default="{ errors }"
                name="Title"
                rules="required"
              >
                <b-form-input
                  v-model="model.title"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="$t('Title')"
                  @input="generateSlug(model.title)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="*Slug:" label-cols-md="12">
              <validation-provider
                #default="{ errors }"
                name="Slug"
                rules="required"
              >
                <b-form-input
                  v-model="model.slug"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Slug"
                  @input="generateSlug(model.slug)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group :label="`*` + $t('Point') + `:`" label-cols-md="12">
              <validation-provider
                #default="{ errors }"
                name="Point"
                rules="required"
              >
                <b-form-input
                  v-model="model.other"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="$t('Point')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group :label="`*` + $t('Point') + `:`" label-cols-md="12">
              <validation-provider
                #default="{ errors }"
                name="Point"
                rules="required"
              >
                <flat-pickr
                  v-model="model.note"
                  class="form-control"
                  style="width: 220px;"
                  :config="{
                    altFormat: 'd-m-Y',
                    dateFormat: 'Y-m-d',
                    altInput: true,
                  }"
                  :placeholder="$t('Date')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- <b-form-group label="*Slug:" label-cols-md="12">
              <validation-provider
                #default="{ errors }"
                name="Slug"
                rules="required"
              >
                <b-form-input
                  v-model="model.slug"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Slug"
                  @input="generateSlug(model.slug)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group> -->
            <b-form-group :label="$t('Short content') + `:`" label-cols-md="12">
              <b-form-textarea
                v-model="model.short_content"
                :placeholder="$t('Short content')"
                rows="8"
              />
            </b-form-group>
            <b-form-group :label="`*` + $t('Content') + `:`" label-cols-md="12">
              <validation-provider
                #default="{ errors }"
                name="Content"
                rules="required"
              >
                <quill-editor
                  id="content"
                  ref="qEdit"
                  v-model="model.content"
                  :options="snowOption"
                  :state="errors.length > 0 ? false : null"
                />
                <input
                  id="getFile"
                  type="file"
                  @change="uploadFunction($event)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </Fieldset>
          <Fieldset id="SEO" :title="$t('SEO Optimization')">
            <SEOInfo v-model="model" />
          </Fieldset>
        </b-col>
        <b-col md="3">
          <div class="position-sticky top">
            <Fieldset id="avatar" :title="$t('Avatar')">
              <FeatureImage
                :avatar="model.avatar"
                @previewFiles="previewFiles"
              />
            </Fieldset>
            <!-- <Fieldset id="category" :title="$t('Category')">
              <validation-provider
                #default="{ errors }"
                name="Blog category"
                rules="required"
              >
                <v-select
                  v-if="categoryOptions"
                  v-model="model.blog_category_id"
                  label="name"
                  :reduce="x => x.id"
                  :options="categoryOptions"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </Fieldset> -->
            <Fieldset id="status" :title="$t('Status')">
              <b-form-group :label="$t('Display on/off')+`:`" label-cols-md="6">
                <b-form-checkbox
                  v-model="model.is_active"
                  checked="true"
                  class="custom-control-success"
                  name="check-button"
                  switch
                />
              </b-form-group>
              <b-form-group :label="$t('Position')+`:`" label-cols-md="6">
                <b-form-input
                  v-model="model.position"
                  type="number"
                  :placeholder="$t('Position')"
                />
              </b-form-group>
              <hr />
              <!-- Submit -->
              <div class="text-right">
                <b-button
                  variant="primary"
                  type="submit"
                  class="text-right"
                  @click.prevent="validationForm"
                >
                  {{ $t('Create new') }}
                </b-button>
              </div>
            </Fieldset>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BForm,
  BFormTextarea,
  BFormCheckbox,
} from 'bootstrap-vue'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import { quillEditor } from 'vue-quill-editor'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BFormGroup,
    // BCard,
    // BCardBody,
    BButton,
    BRow,
    BCol,
    BFormInput,
    // vSelect,
    BForm,
    BFormTextarea,
    quillEditor,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  data() {
    return {
      collapseType: 'margin',
      required,
      model: {
        avatar: this.avatarDefault(),
        title: null,
        position: null,
        is_active: true,
        slug: null,
        content: null,
        short_content: '',
        blog_category_id: null,
        title_page: null,
        meta_description: null,
        meta_keyword: null,
      },
      snowOption: {
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike'],
              ['blockquote', 'code-block'],
              [{ header: 1 }, { header: 2 }],
              [{ list: 'ordered' }, { list: 'bullet' }],
              [{ script: 'sub' }, { script: 'super' }],
              [{ indent: '-1' }, { indent: '+1' }],
              [{ direction: 'rtl' }],
              [{ size: ['small', false, 'large', 'huge'] }],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ color: [] }, { background: [] }],
              [{ font: [] }],
              [{ align: [] }],
              ['clean'],
              ['image'],
              ['link'],
            ],
            handlers: {
              image: () => {
                document.getElementById('getFile').click()
              },
            },
          },
        },
      },
      categoryOptions: [],
    }
  },
  async mounted() {
    // this.categoryOptions = await this.loadCategoryList()
  },
  methods: {
    generateSlug(name) {
      this.model.title_page = name
      this.model.slug = this.convertToSlug(name)
    },
    async validationForm() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            const translation = {}
            translation[await this.getEditLanguage()] = {
              title: this.model.title,
              other: this.model.other,
              short_content: this.model.short_content,
              content: this.model.content,
            }
            const params = {
              site_id: JSON.parse(localStorage.getItem('siteID')).id,
              position: Number(this.model.position),
              is_active: Number(this.model.is_active === true ? 1 : 0),
              title: this.model.title,
              slug: this.model.slug,
              other: this.model.other,
              note: this.model.note,
              short_content: this.model.short_content,
              content: this.model.content,
              avatar: this.model.avatar,
              title_page: this.model.title_page,
              meta_keyword: JSON.stringify(this.model.meta_keyword),
              meta_description: this.model.meta_description,
              translation: JSON.stringify(translation),
              group: 'reward_quest',
            }
            if (
              // eslint-disable-next-line operator-linebreak
              this.model.avatar &&
              this.model.avatar !== null
            ) {
              const formData = new FormData()
              formData.append('site_id', JSON.parse(localStorage.getItem('siteID')).id)
              formData.append('type_table', 'other')
              formData.append('type', 'desktop')
              formData.append('avatar', this.model.avatar)
              formData.append('table_field', 'avatar')
              const resIMG = await Request.post(
                this.$http,
                `${process.env.VUE_APP_API_IMG_URL}/images_only`,
                formData,
              )
              if (resIMG.status === 200) {
                params.avatar = resIMG.data.data.path
                params.avatar_id = resIMG.data.data.id
              }
            }
            const res = await Request.post(
              this.$http,
              `${process.env.VUE_APP_API_URL}/other`,
              params,
            )
            if (res.status === 200) {
              if (res.data.status) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Congratulation !',
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                    text: 'Create Reward quest success',
                  },
                })
                this.$router.push('/reward-quest/list')
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Opps! Something wrong',
                    icon: 'AlertOctagonIcon',
                    variant: 'danger',
                    text: String(this.showError(res.data.error, ',')),
                  },
                })
              }
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(error),
              },
            })
          }
        }
      })
    },
    previewFiles(input) {
      const file = input.target.files[0]
      const reader = new FileReader()
      reader.onload = e => {
        this.model.avatar = e.target.result
      }
      reader.onerror = error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      reader.readAsDataURL(file)
    },
    uploadFunction(e) {
      const vm = this
      const image = e.target.files[0]
      if (image) {
        if (image.size > 5000000) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Opps! Something wrong',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: 'Picture is too large! < 5MB',
            },
          })
        } else {
          const reader = new FileReader()
          reader.readAsDataURL(image)
          reader.onload = ev => {
            vm.postImge(ev.target.result)
          }
        }
      }
    },
    async postImge(image) {
      const range = this.$refs.qEdit.quill.getSelection()
      const params = {
        site_id: JSON.parse(localStorage.getItem('siteID')).id,
        type_table: 'blog',
        type: 'desktop',
        avatar: image,
        table_field: 'ckeditor',
        in_use: 1,
      }
      const res = await Request.post(
        this.$http,
        `${process.env.VUE_APP_API_IMG_URL}/images_only`,
        params,
      )
      this.$refs.qEdit.quill.insertEmbed(
        range.index,
        'image',
        res.data.data.image,
      )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
#getFile {
  display: none;
}
</style>
